import axios from 'axios'
import css from './post-list.css.yml'
import PostItem from '@/components/post-item/post-item.vue'

export default {
  name: 'post-list',

  components: { PostItem },

  mounted() {
    axios.get('/api/posts').then((res) => (this.posts = res.data))
  },

  data() {
    return {
      css: css,
      posts: null,
    }
  },
}
