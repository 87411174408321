import css from './post-item.css.yml'

export default {
  name: 'post-item',

  props: {
    post: Object,
  },

  computed: {
    url() {
      return `/blog/${this.post.slug}`
    },
  },

  data() {
    return {
      css: css,
    }
  },
}
