import { analytics } from '@/mixins/analytics'
import PostList from '@/components/post-list/post-list.vue'

export default {
  name: 'blog',

  mixins: [analytics],

  components: { PostList },
}
