import axios from 'axios'
import css from './show.css.yml'
import { analytics } from '@/mixins/analytics'

export default {
  name: 'blog-post',

  mixins: [analytics],

  mounted() {
    axios.get(`/api/posts/${this.$route.params.slug}`).then((response) => {
      this.post = response.data
    })
  },

  data() {
    return {
      css: css,
      post: null,
    }
  },
}
